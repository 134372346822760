import * as React from "react";
import classNames from "classnames";

import Container from "./Layout/Container";
import BtnFilled from "./reusable/BtnFilled";
// markup
const ContactBlock = ({ children, className = "", btnColor = "blue" }) => {
  return (
    <section className={classNames("contact-block", className)}>
      <Container>
        <div className="text">
          <p>{children}</p>
        </div>
        <div>
          <BtnFilled to="/contact-us" color={btnColor}>
            Get in Touch
          </BtnFilled>
        </div>
      </Container>
    </section>
  );
};

export default ContactBlock;
