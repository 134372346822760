import * as React from "react";
import classNames from "classnames";

import { Helmet } from "react-helmet";

const Seo = ({ title = "", description = "", path = "" }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="canonical" href={`https://www.inviz.ai/${path}`} />
        <meta name="description" content={description} />
      </Helmet>
    </>
  );
};

export default Seo;
