import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
// import { Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import { AnchorLink } from "gatsby-plugin-anchor-links";

import Page from "../components/Layout/Page";
import Container from "../components/Layout/Container";
// import BtnOutlined from "../components/reusable/BtnOutlined";
import BtnFilled from "../components/reusable/BtnFilled";
import ContactBlock from "../components/ContactBlock";

import CasestudiesCarousel from "../components/CaseStudies";
// import Customers from "../components/Customers";
import CustomersAuto from "../components/CustomersAuto";
import HomeOverview from "../components/HomeOverview";
// markup
import Seo from "../components/reusable/Seo";

import {
  HomeCapabilites,
  ArrowRight,
  HomeCapabilitesAI,
  HomeCapabilitesData,
  HomeCapabilitesCloud,
} from "../images/home.svgs";

const IndexPage = () => {
  const breakpoints = useBreakpoint();

  return (
    <Page className={"home"}>
      <Seo
        title="Inviz AI"
        description="Unlock your business potential with our Artificial Intelligence - Machine Learning accelerators and expertise"
        path=""
      />
      <section className="hero">
        <Container>
          <div className="text">
            <h1>
              We build <span>Intelligent solutions</span> for{" "}
              <span>mission-critical</span> applications
            </h1>
            <div>
              <BtnFilled
                to="/capabilities/artificial-intelligence-and-machine-learning"
                color="white"
              >
                Learn More
              </BtnFilled>
            </div>
          </div>
        </Container>
      </section>

      <section className="overview">
        <Container>
          <div className="text">
            <h2 className="intro">
              <b>End-to-End Delivery of Intelligent Applications</b>
            </h2>
            <div className="txt">
              <p>
                We enable companies to solve complex business problems with the
                power of artificial intelligence. Our solutions are built on a
                strong foundation of cloud and data engineering. With our
                enterprise grade solution accelerators and proven track record
                we reduce time to market and risk of software delivery.
              </p>
            </div>
          </div>
          <div className="inner">
            <div className="solutions">
              <div className="items">
                {!breakpoints.sm && (
                  <>
                    <figure className="center-img">
                      <HomeCapabilites />
                    </figure>
                  </>
                )}

                <div className="item ai-ml">
                  {breakpoints.sm && (
                    <figure>
                      <HomeCapabilitesAI />
                    </figure>
                  )}

                  <div className="text">
                    <h3>
                      <b>
                        Artificial Intelligence –<br /> Machine Learning
                        Solution
                      </b>
                    </h3>
                    <div className="cta">
                      <AnchorLink to="/capabilities/artificial-intelligence-and-machine-learning">
                        <span>Explore AI-ML</span> <ArrowRight />
                      </AnchorLink>
                    </div>
                  </div>
                </div>
                <div className="item cloud">
                  {breakpoints.sm && (
                    <figure>
                      <HomeCapabilitesCloud />
                    </figure>
                  )}
                  <div className="text">
                    <h3>
                      <b>Cloud Engineering</b>
                    </h3>
                    <div className="cta">
                      <AnchorLink to="/capabilities/cloud-engineering">
                        <span>Explore Cloud Engineering</span> <ArrowRight />
                      </AnchorLink>
                    </div>
                  </div>
                </div>
                <div className="item data">
                  {breakpoints.sm && (
                    <figure>
                      <HomeCapabilitesData />
                    </figure>
                  )}
                  <div className="text">
                    <h3>
                      <b>Data Engineering</b>
                    </h3>
                    <div className="cta">
                      <AnchorLink
                        to="/capabilities/data-engineering"
                        color="blue"
                      >
                        <span> Explore Data Engineering</span> <ArrowRight />
                      </AnchorLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="impact">
        <Container>
          <h2>
            <b>Our Impact</b>
          </h2>
          <div className="items">
            <div className="item">
              <div>
                <strong>
                  <span>$250 M+</span>
                </strong>
                <span>
                  <i>Revenue</i> Unlocked
                </span>
              </div>
            </div>
            <div className="item">
              <div>
                <strong>
                  <span>200 M+</span>
                </strong>
                <span>
                  <i>Users</i> Impacted
                </span>
              </div>
            </div>
            <div className="item">
              <div>
                <strong>
                  <span>4</span>
                </strong>
                <span>
                  <i>Fortune</i> 500 Clients
                </span>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="clients">
        <Container>
          <h2>
            <b>
              Trusted by <i>global companies</i>
            </b>
          </h2>
          <div className="items">
            {
              // <Customers />
            }
            <CustomersAuto />
          </div>
        </Container>
      </section>
      <section className="case-studies">
        <Container>
          <h2>
            <b>Case Studies</b>
          </h2>
          <CasestudiesCarousel />
        </Container>
      </section>

      <ContactBlock className="home" btnColor="white">
        Start your <i>AI-ML journey</i> today
      </ContactBlock>
      <section className="careers">
        <Container>
          <div className="text">
            <h2>
              <b>We are Hiring </b>
            </h2>
            <div>
              <p>
                Our flat-hierarchy and inclusive culture provides exciting and
                empowering opportunities for you to build a strong career.
              </p>
              <p>
                Together, we will solve crucial business problems by leveraging
                technology and making a world of difference.{" "}
              </p>
            </div>
            <div className="cta">
              <BtnFilled
                to="https://careers.inviz.ai/jobs/Careers"
                color="blue"
                context="external"
              >
                Open Positions
              </BtnFilled>
            </div>
          </div>
        </Container>
      </section>
    </Page>
  );
};

export default IndexPage;
