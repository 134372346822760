import * as React from "react";
import classNames from "classnames";

import { StaticImage } from "gatsby-plugin-image";

const ClientsList = ({ className = "" }) => {
  return (
    <div className={classNames("clients-set", className)}>
      <div className="item">
        <figure>
          <StaticImage
            src="./../images/home/clients/falabella.png"
            alt="Falabella"
            loading="lazy"
            placeholder="blurred"
            layout="fullWidth"
          />
        </figure>
      </div>
      <div className="item">
        <figure>
          <StaticImage
            src="./../images/home/clients/lowes.png"
            alt="Falabella"
            loading="lazy"
            placeholder="blurred"
            layout="fullWidth"
          />
        </figure>
      </div>

      <div className="item">
        <figure>
          <StaticImage
            src="./../images/home/clients/tata-cliq.png"
            alt="Tata Cliq"
            loading="lazy"
            placeholder="blurred"
            layout="fullWidth"
          />
        </figure>
      </div>

      <div className="item">
        <figure>
          <StaticImage
            src="./../images/home/clients/aditya-birla.png"
            alt="Falabella"
            loading="lazy"
            placeholder="blurred"
            layout="fullWidth"
          />
        </figure>
      </div>

      <div className="item">
        <figure>
          <StaticImage
            src="./../images/home/clients/safe-security.png"
            alt="Falabella"
            loading="lazy"
            placeholder="blurred"
            layout="fullWidth"
          />
        </figure>
      </div>
      <div className="item">
        <figure>
          <StaticImage
            src="./../images/home/clients/aitomatic.png"
            alt="Falabella"
            loading="lazy"
            placeholder="blurred"
            layout="fullWidth"
          />
        </figure>
      </div>
      <div className="item">
        <figure>
          <StaticImage
            src="./../images/home/clients/covalency.png"
            alt="Falabella"
            loading="lazy"
            placeholder="blurred"
            layout="fullWidth"
          />
        </figure>
      </div>
      <div className="item">
        <figure>
          <StaticImage
            src="./../images/home/clients/clover-bay.png"
            alt="Falabella"
            loading="lazy"
            placeholder="blurred"
            layout="fullWidth"
          />
        </figure>
      </div>
    </div>
  );
};

const CustomersAuto = ({ className = "" }) => {
  return (
    <div className={classNames("customers-infinite", className)}>
      <div className="inner-wrapper">
        <ClientsList className="set-1" />
        <ClientsList className="set-2" />
      </div>
    </div>
  );
};

export default CustomersAuto;
